@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import 'react-phone-input-2/lib/style.css';
@import "react-datetime/css/react-datetime.css";
@import 'react-quill/dist/quill.snow.css';

.content-container {
	position: relative;
	margin-top: 2rem;
	padding-bottom: 0.5rem;
	background: white;
	border-radius: 10px;
	filter: drop-shadow(0px 4px 30px rgba(137, 145, 162, 0.12));
	/* overflow: hidden; */
	z-index: 999;
}

.content-container-transparent {
	background: transparent;
	background-color: transparent;
}

.content-header {
	border-radius: 16px 16px 0 0;
	padding: var(--content-padding-y) var(--content-padding);
}

.content-padding {
	padding: var(--content-padding);
}

.content-padding-y {
	padding-top: var(--content-padding-y) !important;
	padding-bottom: var(--content-padding-y) !important;
}

.content-padding-x {
	padding-left: var(--content-padding-x) !important;
	padding-right: var(--content-padding-x) !important;
}

.content-margin-x {
	padding: 1% var(--content-padding-x) 2% var(--content-padding-x);
}

.content-table {
	width: 100%;
	max-width: 100%;
	background-color: white; 
	padding: 0;
	box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.06);
	border-radius: 8px;
	border: 1px solid #E5E7EB;
}

.content-table .row {
	padding: 0.8rem 1rem;
	border-bottom: 1px solid var(--theme-senary);
}

.content-table .row * {
	color: var(--theme-septenary);
	font-size: var(--theme-primary);
}

.content-table .content-table-header {
	color: var(--theme-senary);
	background-color: var(--theme-denary);
	font-size: var(--theme-primary);
	font-weight: 600;
	width: 100%;
	padding: 0.01rem 0.8rem;
	border-top-left-radius: 8px;
  	border-top-right-radius: 8px;
}

.content-table .content-table-header .row {
	border-top-left-radius: 8px;
  	border-top-right-radius: 8px;
}

.modal-text-input {
	font-size: var(--font-size-large);
	font-family: "Open-Sans";
	border-radius: 5px;
	border: #C7D4DE 1px solid;
	padding: 0.01rem 0.8rem;
	color:  #1D2537;
}

.modal-select-control {
	height: 5vh;
	font-family: "Open-Sans";
}

.modal-label {
	font-family: "Open-Sans";
}

.modal-form-control {
	display: block;
	margin: 0.5rem 0.2rem;
}

.modal-form-control label {
	font-size: var(--font-size-large);
	font-weight: bold;
}

.content-table .content-table-body {
	width: 100%;
	padding: 0.01rem 0.8rem;
	box-shadow: 0px 1px 1px rgba(0, 0, 0, 0);
	border-bottom-left-radius: 10px;
  	border-bottom-right-radius: 10px;
}

.content-table .content-table-body .row  {
	padding: 1rem;
}

.content-table .content-table-body .active {
	background: var(--theme-octonary);
}

.content-table .content-table-body [class*="col"] {
	display: flex;
	align-items: center;
}

.content-table .header-height {
	height: 40px;
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

.content-datetime {
	align-self: center;
	border: 0;
}

.content-footer {
	padding: var(--content-padding);
}

.login-container {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	width: 100%;
}

.login-content-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 25%;
}

.login-content-textfield {
	width: 100%;
	margin: 15px 0 !important;
}

.logo {
	width: 70%;
}

.otp-verification-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
	font-family: Arial, sans-serif;
}

.otp-heading {
	font-size: 24px;
	margin-bottom: 20px;
}

.otp-form {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 80%;
	max-width: 300px;
}

.otp-input-container {
	display: flex;
	justify-content: center;
	margin-bottom: 20px;
}

.otp-input {
	width: 50px;
	height: 50px;
	font-size: 20px;
	margin: 0 5px;
	text-align: center;
	border: 1px solid #ccc;
	border-radius: 4px;
}

.content-textfield {
	padding: 1rem 0.8rem !important;
}

.modal-header {
	padding: 1.7rem;
}

.modal-body {
	padding: 1.7rem;
}

.modal-footer {
	padding: 1.7rem;
}

.modal-title {
	font-size: 1.2rem;
	font-weight: bold;
}

.lesson-accordion {
	box-shadow: 0px 4px 30px 0px rgba(137, 145, 162, 0.12) !important;
}

.lesson-accordion-action-btns {
	color: var(--theme-secondary) !important;
}

.lesson-accordion-action-btns:hover {
	color: var(--theme-green) !important;
}

.reflection-response-container {
	overflow: hidden;
	display: flex;
	border-radius: 16px;
	margin: 0 -2.3rem -1.5rem -2.3rem;
}

.reflection-response {
	display: inline-flex;
	padding: 1.5rem 2.3rem;
}

.reflection-response-border {
	display: inline-flex;
	width: 5px;
	background: var(--theme-primary);
	opacity: 0.5;
}

.overview-description {
	color:black !important;
	text-align: justify;
	word-wrap: break-word;
	overflow-wrap: break-word;
	white-space: pre-wrap;
}

.table-empty-rows {
	height: 6rem !important;
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

.modal-checkbox{
	margin: 0.2rem 0 0.2rem 0;
}

.library-gutter {
	--bs-gutter-y: 2.5%;
	--bs-gutter-x: 2%;
}

.access-button {
	width: 5rem;
}

.in-page-select {
	z-index: 10;
}

.darken-background {
	position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.8; /* You can adjust the opacity as needed */
    z-index: 1000; /* Ensure the overlay is on top of other elements */
}

.stacked-modal {
	position: relative;
	z-index: 999; /* Higher z-index to make the stacked modal appear on top */
}

.green-checkmark::before {
	content: '\2713'; /* Unicode checkmark symbol */
	color: green;
	font-size: 20px; /* Adjust the size as needed */
}

.quiz-container {
	margin: 0.2rem;
	margin-bottom: 1rem;
	border-radius: 10px;
}

.chat-thread-container {
	height: 100%;
	max-height: 60vh;
	max-width: 75%;
	min-width: 75%;
	overflow-x: auto;
	overflow-y: scroll;
}

.chat-container {
	display: flex;
	max-width: 75%;
	padding: 15px;
	margin-bottom: 1rem;
	border-radius: 15px;
	line-height: 1.4;
}

.element-chat-container {
	display: flex;
	max-height: 45vh;
	overflow-x: auto;
	overflow-y: scroll;
	margin-bottom: 1rem;
	border-radius: 15px;
	line-height: 1.4;
}

.bubble-chat-container {
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal; /* Ensures text wraps properly */
    padding: 10px 15px;
	white-space: pre-line;
}

.user-chat-container {
    border-radius: 10px;
    max-width: 85%;
	color: black;
	background-color: var(--theme-senary);
}

.ai-chat-container {
	color: black;
}

.gpt-starter-container {
	border-radius: "15px";
	overflow: "hidden";
	text-align: "center";
	color: "black";
	padding: '16px';
	width: 300;
	position: 'relative';
	margin: 1rem;
	border-radius: 20px;
}

.q-and-a-container {
	padding: 1rem;
}

.reply-container {
	padding: 0.5rem;
}

.modal-container {
	margin: 0.2rem;
	margin-bottom: 1rem;
	padding: 1rem;
	border: solid var(--theme-senary) 1px;
	border-radius: 10px;
	box-shadow: 0 0 8px var(--theme-senary);
}

.child-box {
    margin: 0.5rem 0;
    padding: 0.5rem;
    border: solid var(--theme-quinary) 1px;
    border-radius: 8px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    background-color: var(--theme-background-nonary);
}

.element-checkbox-label {
	font-size: 1.3rem;
	padding-top: 1px;
}

.element-checkbox-label :hover{
	color: var(--theme-primary) !important;
}

.quiz-selection {
	color: black !important;
}

.quiz-selection :hover{
	color: var(--theme-primary) !important;
}

.draggable-choice {
	border: 2px solid var(--theme-secondary);
	margin-bottom: 0.1rem;
  	border-radius: 5px; 
	padding: 0.5rem 0.2rem 0.2rem 1rem;
}

.phone-input-container {
	width:100%;
	height: 3.5rem; 
	margin-bottom: 1rem;
}

.phone-input-container .form-control:focus {
	box-shadow: 0px 0px 0px 2px #007bff;
}

.custom-file {
	position: relative;
	display: inline-block;
	width: 100%;
	margin-bottom: 0;
}

.custom-file-input {
	position: relative;
	z-index: 2;
	width: 100%;
	padding: 16.5px 14px;
	margin: 0;
	opacity: 0;
}

.custom-file-label {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	width: 100%;
	overflow: hidden;
	z-index: 1;
	padding: 15.5px 14px;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

.custom-file-input:lang(en)~.custom-file-label::after {
	content: "Browse";
}

.custom-file-label::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 3;
	display: block;
	padding: 16.5px 14px;
	line-height: 1.5;
	color: #495057;
	content: "Browse";
	background-color: #e9ecef;
	border-left: inherit;
	border-radius: 0 0.25rem 0.25rem 0;
}

.custom-datetime .rdtInput {
	display: none;
} 


.rdt {
	position: relative;
}

.rdtPicker {
	display: none;
	position: absolute;
	width: 250px;
	padding: 4px;
	margin-top: 1px;
	z-index: 99999 !important;
	background: #fff;
	box-shadow: 0 1px 3px rgba(0,0,0,.1);
	border: 1px solid #dddddd;
	/* bottom:0; */
	right: 0;
}

.action-step{
	/* border: solid red 1px; */
	border-radius: 10px;
	box-shadow: 0 0 5px rgba(213, 10, 10, 0.3);
	margin-top: 1rem;
	margin-bottom: 1rem;
	margin-left: 1rem;
	margin-right: 1rem;
	padding: 1rem;
}

.action-step-summary{
	/* border: solid black 1px; */
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.action-summary {
	border-radius: 10px;
	border: 1px solid var(--theme-secondary);
	overflow: hidden;
}

.action-not-submitted {
	border-radius: 10px;
	box-shadow: 0 0 5px rgba(213, 10, 10, 0.3);
	background-color: var(--theme-senary);
}

.word-cloud {
	width: 500;
	height: 300;
}

.word-cloud-main-container {
	display: flex;
}

.word-cloud-main-container .wide {
	flex-direction: row-reverse;
}

.generated-quiz {
	border-style: solid;
	border-width: 0.1rem;
	border-color: var(--theme-secondary);
	background-color: var(--theme-denary);
	margin-bottom: 0.1rem;
}

.mobile-container {
    width: 600px;
    max-width: 550px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    overflow-y: auto;
    overflow-x: none;
}

.qa-wide-container {
    width: 600px;
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    overflow-y: auto;
    overflow-x: none;
}

.mobile-div {
    display: flex;
    background-color: var(--theme-quick-access);
    width: 100%;
    height: 2rem;
    max-width: 600px;
    color: #fff;
	font-size : 1rem;
}

@media (min-width: 601px) {
    .mobile-container {
        max-width: 600px; /* Set maximum width to 800px to prevent further expansion */
    }
}

.close-button {
	position: 'absolute';
	bottom: -5%;
	right: -90%;
};

@media (max-width: 720px) {
	/* Styles for screens with a maximum width of 768px (adjust as needed) */
	.survey-shrink {
	  flex-direction: column;
	}
}

.convo-starter-table {
	border: #6B7280 solid 1px;
}

.context-menu-item {
	display: block;
	padding: 0.5rem 0.75rem;
	box-shadow: 1px 1px 1px #888888;
}

.context-menu-item:hover {
	color: var(--theme-primary);
	cursor: pointer;
}

.language-dropdown-item {
	z-index: 99999;
}

.clickable-text {
	text-decoration: underline;
	color: blue;
	cursor: pointer;
}

.cursor-pointer {
	text-decoration: none;
	cursor: pointer;
}

.position-permission {
	margin-bottom: 0.5rem;
    margin-left: 1rem;
    margin-right: 1rem;
}

.card-container {
	border-radius: 16px 0px 0px 0px !important;
	box-shadow: 10px #8991A21F !important;
	gap: 0px;
	opacity: 0px;
}

.radio-button-group {
	display: flex;
 	 align-items: center;
}

@media (max-width: 700px) {
	.radio-button-group  {
	  display: block; /* Stacks radio buttons vertically */
	  margin-bottom: 20px; /* Adds space between stacked radio buttons */
	}
}

.ratings-card {
	border: 2px solid #333;
	border-radius: 15px;
	padding: 10px;
	margin: 10px; 
}

.responsive-qa-logo {
	width: 20vw;
	background-color: 'transparent';
	object-fit: 'contain';

	@media (min-width: 600px) {
		width: 17vw;
	}

	@media (min-width: 960px) {
		width: 13vw;
	}

	@media (min-width: 1280px) {
		width: 7vw;
	}
}
  
#meetingSDKElement {
	top: 59px !important;
	left: 0 !important;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	width: 244px; /* width of speaker and ribbon view */
  }

.zoom-main-container {
	display: flex;
	justify-content: flex-end;
	padding: 10px;
	max-height: 100%;
}

.zoom-vid-container {
	width: 300px;
	height: 35vh;
	margin: 0.2rem;
	margin-bottom: 1rem;
	padding: 1rem;
	border: solid var(--theme-senary) 1px;
	border-radius: 10px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.zoom-control-container {
	width: 300px;
	height: 60vh;
	margin: 0.2rem;
	border: solid var(--theme-primary) 1px;
	border-radius: 10px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.zoom-chat-container {
	display: block;
	width: 20vw;
	height: 100%;
	margin: 0.2rem;
	margin-left: 0.5rem;
	padding: 1rem;
	border: solid var(--theme-senary) 1px;
	border-radius: 10px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.zoom-content-container {
	display: block;
	width: 62vw;
	height: 100%;
	margin: 0.2rem;
	margin-left: 0.75rem;
	padding: 1rem;
	border: solid var(--theme-senary) 1px;
	border-radius: 10px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.qa-control-container {
	display: flex;
	align-items: flex-end;
	justify-content: center;
	border: solid black 1px;
	height: 100%;
	padding: 0;
	margin: 0;
}

.share-screen-container{
	width: 100%;
	padding: 1rem;
}

/* Small Devices (Screens below 768px) */
@media screen and (max-width: 767px) {
}

/* Medium Devices (Screens below 992px) */
@media screen and (max-width: 991px) {
	.word-cloud-main-container.wide {
		flex-direction: column;
		align-items: center;
	}

	.word-cloud-main-container > div {
		width: 100% !important;
	}
}

/* Large Devices (Screens below 1200px) */
@media screen and (max-width: 1199px) {
}

/* Extra Large Devices (Screens below 1600px) */
@media screen and (max-width: 1599px) {
}
  
#meetingSDKElement {
	top: 59px !important;
	left: 0 !important;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	width: 244px; /* width of speaker and ribbon view */
  }

.zoom-main-container {
	display: flex;
	justify-content: flex-end;
	padding: 10px;
	max-height: 100%;
}

.zoom-vid-container {
	width: 300px;
	height: 35vh;
	margin: 0.2rem;
	margin-bottom: 1rem;
	padding: 1rem;
	border: solid var(--theme-senary) 1px;
	border-radius: 10px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.zoom-control-container {
	width: 300px;
	height: 60vh;
	margin: 0.2rem;
	border: solid var(--theme-primary) 1px;
	border-radius: 10px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.zoom-chat-container {
	display: block;
	width: 20vw;
	height: 100%;
	margin: 0.2rem;
	margin-left: 0.5rem;
	padding: 1rem;
	border: solid var(--theme-senary) 1px;
	border-radius: 10px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.zoom-content-container {
	display: block;
	width: 62vw;
	height: 100%;
	margin: 0.2rem;
	margin-left: 0.75rem;
	padding: 1rem;
	border: solid var(--theme-senary) 1px;
	border-radius: 10px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.qa-control-container {
	display: flex;
	align-items: flex-end;
	justify-content: center;
	border: solid black 1px;
	height: 100%;
	padding: 0;
	margin: 0;
}

.share-screen-container{
	width: 100%;
	padding: 1rem;
}
.company-details-form {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.company-details-form.icon-logo-form {
	transform: translateX(30%);
}

.company-details-form.wide-logo-form {
	transform: translateX(-30%);
	pointer-events: none;
}

.company-details-form.wide-logo-form > center > label {
	pointer-events: all;
}

.company-details-form.wide-logo-form > center > button {
	pointer-events: all; 
}

.comment-replies-cont {
	display: flex;
	flex-direction: column;
	row-gap: 2rem;
}
.comment-replies-cont-sub-replies {
	overflow-y: scroll;
	max-height: 32rem;
	padding: 1rem;
}

.users-bubble-container {
	display: flex;
	align-items: center;
}

.users-bubble-container-items {
	position: relative;
	border: 1px solid white;
	border-radius: 50%;
	object-fit: contain;
	width: 3rem;
	height: 3rem;
}

.users-bubble-container-number-avatar {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--theme-quaternary);
	border-radius: 50%;
	width: 3rem;
	height: 3rem;
	margin-left: -1rem;
	border: 1px solid white;
	font-size: var(--font-size-primary);
	font-weight: var(--font-weight-bold);
	color: white !important;
	position: relative;
	z-index: 0;
}

.qa-boards-table-rows > div:nth-child(even) {
    background-color: var(--theme-denary);
}

.qa-boards-table-rows-link-content {
	cursor: pointer;
	pointer-events: auto;
}
.company-details-form {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.company-details-form.icon-logo-form {
	transform: translateX(30%);
}

.company-details-form.wide-logo-form {
	transform: translateX(-30%);
	pointer-events: none;
}

.company-details-form.wide-logo-form > center > label {
	pointer-events: all;
}

.company-details-form.wide-logo-form > center > button {
	pointer-events: all;
}

.learning-habit-settings-container {
	margin-left: 20rem;
	margin-right: 20rem;
	margin-bottom: 3rem;
}

.dashboard-action-steps {
	width: 80% !important;
	padding-inline: 26px;
	padding-top: 26px;
}

.dashboard-action-steps-items {
	margin: 0 !important;
	max-height: 900px;
	overflow-y: scroll;
}

/* Small Devices (Screens below 768px) */
@media screen and (max-width: 767px) {
	.learning-habit-settings-container {
		margin-left: 10rem;
		margin-right: 10rem;
	}
}

/* Medium Devices (Screens below 992px) */
@media screen and (max-width: 991px) {
	.word-cloud-main-container.wide {
		flex-direction: column;
		align-items: center;
	}

	.word-cloud-main-container > div {
		width: 100% !important;
	}

	.learning-habit-settings-container {
		margin-left: 10rem;
		margin-right: 10rem;
	}
}

/* Large Devices (Screens below 1200px) */
@media screen and (min-width: 992px) and (max-width: 1199px) {
	.learning-habit-settings-container {
		margin-left: 10rem;
		margin-right: 10rem;
	}
}

/* Extra Large Devices (Screens between 1200px and 1599px) */
@media screen and (min-width: 1200px) and (max-width: 1399px) {
	.learning-habit-settings-container {
		margin-left: 10rem;
		margin-right: 10rem;
	}
}

/* Extra Large Devices (Screens between 1200px and 1599px) */
@media screen and (min-width: 1400px) and (max-width: 1599px) {
	.learning-habit-settings-container {
		margin-left: 20rem;
		margin-right: 20rem;
	}
}