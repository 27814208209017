body, html, #root {
	height: 100%;
	width: 100%;
}

body {
	font-family: Poppins, sans-serif;
}

@media screen and (max-width: 375px) {
	body {
	  align-items: flex-start;
	}
}

@media (max-width: 600px) {
	.stack-on-small-screens {
	  flex-direction: column;
	}
}

:root {
	--theme-green: #37CB97;
	--theme-green-secondary: #DBFFE7;
	--theme-red: #F54545;
	--theme-blue: #0000EE;
	--theme-primary: #F2663D;
	--theme-secondary: #8991A2;
	--theme-tertiary: #E15C2D;
	--theme-quaternary: #b5b5c3;
	--theme-quinary: #6B7280;
	--theme-senary: #E5E7EB;
	--theme-septenary: #454F63;
	--theme-octonary: #EDF3F9;
	--theme-nonary: #EAEAEA;
	--theme-denary: #F9FAFB;
	--theme-black: #030303;
	--theme-quick-access: #F57F4B;
	--theme-tellix-access: #CAA586;
	--theme-alt-red: #FFDCDC;
	--theme-alt-orange: #FFC1A6;
	--theme-alt-green: #DBFCEA;
	--theme-sidebar-secondary-color: #FFC177;
	--theme-border-color: #F0F2F4;
	
	--font-size-primary: 1rem;
	--font-size-secondary: 0.87rem;
	--font-size-small: 0.72rem;
	--font-size-xm: 0.92rem;
	--font-size-medium: 1.14rem;
	--font-size-large: 1.22rem;
	--font-size-l2: 1.32rem;
	--font-size-l3: 1.42rem;
	--font-size-l4: 1.52rem;
	--font-size-l5: 1.62rem;
	--font-size-xl: 1.72rem;
	--font-size-xl2: 1.82rem;
	--font-size-xl3: 1.92rem;
	--font-size-xxl: 2.12rem;
	--font-size-xxxl: 3.2rem;

	--font-weight-light: 300;
	--font-weight-regular: 400;
	--font-weight-medium: 500;
	--font-weight-semibold: 600;
	--font-weight-bold: 700;
	--font-weight-black: 900;

	--content-padding: 2rem;
	--content-padding-x: 2rem;
	--content-padding-y: 1rem;

	--sidebar-primary-options-minimized-width: 60px;
	--sidebar-primary-options-open-width: 260px;
}

#root {
	display: flex;
	font-size: var(--font-size-primary) !important;
}

.bg-green {
	background: var(--theme-green) !important;
}

.bg-red {
	background: var(--theme-red) !important;
}

.bg-primary {
	background: var(--theme-primary) !important;
}

.bg-secondary {
	background: var(--theme-secondary) !important;
}

.bg-tertiary {
	background: var(--theme-tertiary) !important;
}

.bg-quaternary {
	background: var(--theme-quaternary) !important;
}

.bg-quinary {
	background: var(--theme-quinary) !important;
}

.bg-senary {
	background: var(--theme-senary) !important;
}

.bg-septenary {
	background: var(--theme-septenary) !important;
}

.bg-octonary {
	background: var(--theme-octonary) !important;
}

.bg-nonary {
	background: var(--theme-nonary) !important;
}

.bg-quick-access {
	background: var(--theme-quick-access) !important;
}

.bg-white {
	background: #FFFF !important;
}

.bg-tellix {
	background: var(--theme-tellix-access) !important;
}

.bg-quick-access {
	background: var(--theme-quick-access) !important;
}

.color-white {
	color: #FFFF !important;
}

.color-black {
	color: black !important;
}

.color-green {
	color: var(--theme-green) !important;
}

.color-red {
	color: var(--theme-red) !important;
}

.color-blue {
	color: var(--theme-blue) !important;
}

.color-primary {
	color: var(--theme-primary) !important;
}

.color-secondary {
	color: var(--theme-secondary) !important;
}

.color-tertiary {
	color: var(--theme-tertiary) !important;
}

.color-quaternary {
	color: var(--theme-quaternary) !important;
}

.color-quinary {
	color: var(--theme-quinary) !important;
}

.color-senary {
	color: var(--theme-senary) !important;
}

.color-septenary {
	color: var(--theme-septenary) !important;
}

.color-octonary {
	color: var(--theme-octonary) !important;
}

.color-quick-access {
	color: var(--theme-quick-access) !important;
}

.color-tellix {
	color: var(--theme-tellix-access) !important;
}

.color-green-hover:hover {
	color: var(--theme-green);
}

.color-primary-hover:hover {
	color: var(--theme-primary);
}
  
.color-red-hover:hover {
	color: var(--theme-red);
}

.color-blue:hover {
	color: var(--theme-blue) !important;
}

.outline-primary {
	border: 1px solid var(--theme-primary) !important;
}

.outline-tertiary {
	border: 1px solid var(--theme-tertiary) !important;
}

.outline-secondary {
	border: 1px solid var(--theme-secondary) !important;
}

.outline-senary {
	border: 1px solid var(--theme-senary) !important;
}

.outline-black {
	border: 1px solid black !important;
}

.font-size-primary {
	font-size: var(--font-size-primary) !important;
}

.font-size-secondary {
	font-size: var(--font-size-secondary) !important;
}

.font-size-small {
	font-size: var(--font-size-small) !important;
}

.font-size-xm {
	font-size: var(--font-size-xm) !important;
}

.font-size-medium {
	font-size: var(--font-size-medium) !important;
}

.font-size-large {
	font-size: var(--font-size-large) !important;
}

.font-size-l2 {
	font-size: var(--font-size-l2) !important;
}

.font-size-l3 {
	font-size: var(--font-size-l3) !important;
}

.font-size-l4 {
	font-size: var(--font-size-l4) !important;
}

.font-size-l5 {
	font-size: var(--font-size-l5) !important;
}

.font-size-xl {
	font-size: var(--font-size-xl) !important;
}

.font-size-xl2 {
	font-size: var(--font-size-xl2) !important;
}

.font-size-xxl {
	font-size: var(--font-size-xxl) !important;
}

.font-size-xxxl {
	font-size: var(--font-size-xxxl) !important;
}

.font-weight-light {
	font-weight: var(--font-weight-light) !important;
}

.font-weight-regular {
	font-weight: var(--font-weight-regular) !important;
}

.font-weight-medium {
	font-weight: var(--font-weight-medium) !important;
}

.font-weight-semibold {
	font-weight: var(--font-weight-semibold) !important;
}

.font-weight-bold {
	font-weight: var(--font-weight-bold) !important;
}

.font-weight-black {
	font-weight: var(--font-weight-black) !important;
}

.font-primary {
	font-family: Poppins !important;
}

.font-underline {
	text-decoration: underline !important;
}

.pointer {
	cursor: pointer;
}

.disabled {
	color: white !important;
	opacity: 0.75;
}

.fit-content {
	width: fit-content;
}

.mx-8 {
	margin-left: 8rem !important;
	margin-right: 8rem !important;
}

.mx-10 {
	margin-left: 10rem !important;
	margin-right: 10rem !important;
}

/* scrollbar */

::-webkit-scrollbar {
	height: 6px;
	width: 10px;
	border-radius: 5px;
}

/* Track */
::-webkit-scrollbar-track {
	display: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

/* scrollbar */

[class*="col-"] {
	margin: 0;
	padding: 0;
}

[class*="row"] {
	margin: 0;
	padding: 0;
}

.theme-left-container {
	background: white;
	height: 100%;
	float: left;
	position: relative;
	z-index: 99;
}

.theme-app-icon {
	position: relative;
	background: white;
	margin-bottom: .5rem;
	width: 30px;
	height: 30px;
	border-radius: 7px;
}

.theme-navigation {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 10% 0;

	position: relative;
	float: left;
	width: 60px;
	height: 100%;

	background: var(--theme-primary);
}

.theme-navigation ul {
	position: relative;
	list-style-type: none;
	margin: 0;
	padding: 0;
	color: white;
}

.theme-navigation ul li {
	position: relative;
	margin: 0.6rem 0;
	padding: 1rem 0.7rem;
	border-radius: 6px;
	cursor: pointer;
}

.theme-navigation ul li.active {
	background: var(--theme-tertiary);
}

.theme-sub-nav {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 10% 5%;
	overflow: auto;

	position: relative;
	float: left;
	width: 300px;
	height: 100%;

	background: white;
	/* color: var(--theme-primary); */
	color: black;

	border: 1px solid var(--theme-nonary);
	border-radius: 1px;
}

.theme-sub-nav-icon {
	padding: 0;
	margin: 0;
	width: 16px;
	height: 16px;
}

.theme-sub-nav ul {
	list-style-type: none;
	width: 100%;
	margin: 0.5rem 0;
	padding: 0;
}

.theme-sub-nav ul li {
	padding: 1rem 1.2rem;
	margin: 0.3rem 0;
	position: relative;
	border-radius: 6px;
	cursor: pointer;
}

.theme-sub-nav li.active {
	color: white;
	background: var(--theme-primary);
}

.theme-sub-nav .subNavAccordionList li {
	padding: 0.8rem 0 0.8rem 20px;
	/* border: 1px solid black; */
}

.theme-sub-nav-title {
	font-size: var(--font-size-medium);
	color: var(--theme-quinary);
	padding-top: 1rem;
	padding-left: 1.2rem;
}

.theme-sub-nav ul li span {
	margin-left: 0.7rem;
}

.theme-sub-nav ul li div {
	margin-left: 0.7rem;
}

.theme-sub-nav a {
	color: inherit;
	text-decoration: none;
}

.theme-right-container {
	flex: 1;
	background: #FCFEFF;
	color: var(--theme-quinary);
	height: 100%;
	float: left;
	overflow: auto;
}

.theme-header {
	display: flex;
	align-items: center;
	width: 100%;
	height: 65px !important;
	min-height: 65px !important;
	max-height: 65px !important;
	background: white;
	box-shadow: 0px 4px 30px 0px rgba(137, 145, 162, 0.12);
	flex-shrink: 0;
}

.theme-content {
	position: relative;
	display: block;
	width: 100%;
	padding-bottom: 2rem;
	padding: 1% var(--content-padding-x) 2% var(--content-padding-x);
	overflow-x: hidden;
}

.theme-content-no-block-padding.theme-content {
	padding-inline: 0;
	padding-top: 0;
}

.container-top-margin {
	margin-top: 0 !important;
}

.container-padding {
	padding: 1% var(--content-padding-x) 2% var(--content-padding-x);
}

.container-margin {
	margin: 1% var(--content-padding-x) 2% var(--content-padding-x);
}

.container-margin-x {
	margin-left: var(--content-padding-x);
	margin-right: var(--content-padding-x);
}

.library-details-page-sub-container {
	padding: 1% var(--content-padding-x) 2% var(--content-padding-x);
}

.library-card-categories {
	position: absolute;
	margin-top: 12px;
	margin-left: 10px;
	display: flex;
	flex-direction: column;
	row-gap: 6px;
}

.library-card-categories-item {
	padding: 2px 8px;
	border-radius: 4px;
	background-color: var(--theme-tertiary);
	text-align: start;
}

.theme-header-breadcrumbs {
	width: fit-content;
	height: 41px;
	display: flex;
	vertical-align: middle;
	align-items: center;
	margin-left: 32px;
}

.theme-header-breadcrumbs-left-text {
	font-weight: var(--font-weight-regular) !important;
	font-size: 14px !important;
	padding: 10px 16px 10px 10px;
	border-top: 1px solid #EFEFEF;
	border-bottom: 1px solid #EFEFEF;
	border-left: 1px solid #EFEFEF;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}

.theme-header-breadcrumbs-selection {
	border-radius: 0 !important;
	border-top-right-radius: 4px !important;
	border-bottom-right-radius: 4px !important;
	height: 100% !important;
}

.theme-header-breadcrumbs-selection>div:first-child {
	padding: 10px 10px 10px 10px;
	font-weight: var(--font-weight-regular) !important;
	font-size: 14px !important;
}

.theme-header-breadcrumbs-selection>fieldset {
	border-color: #EFEFEF !important;
}

.theme-header-breadcrumbs a {
	text-decoration: none;
	color: black;
}

.theme-header-breadcrumbs a.active {
	font-weight: bold;
	color: var(--theme-quinary);
}

.theme-header-profile {
	display: inline-block;
	width: fit-content;
	height: 50px;
	vertical-align: top;
	margin-left: auto;
	margin-right: 32px;
}

.theme-header-profile-container {
	position: relative;
	display: inline-block;
	float: right;
	/* padding: 5px 12px; */
	padding: 6px;
	/* Add padding to create space inside the container */
	line-height: 1.5;
	transition: background-color 0.2s;
	border-radius: 8px;
}

.theme-header-profile-container:hover {
	background-color: var(--theme-primary);
	border-radius: 8px;
	color: white;
}

.theme-header-notification {
	margin-top: 13px;
	margin-right: 10px;
	vertical-align: top;
	float: right;
}

.theme-header-profile-picture {
	position: relative;
	display: inline-block;
	background: black;
	color: white;
	width: 40px;
	height: 40px;
	line-height: 40px;
	border-radius: 7px;
	float: right;
	text-align: center;
	border-radius: 50%;
	overflow: hidden;
}

.theme-header-initials {
	display: inline-block;
	position: relative;
	vertical-align: middle;
	font-weight: bold;
	font-size: large;
	cursor: pointer;
}

.theme-header-initials>img {
	border: none !important;
}

.theme-header-profile-name {
	display: inline-block;
	line-height: 40px;
	vertical-align: top;
	margin-left: 10px;
	float: right;
	cursor: pointer;
}

.theme-logo {
	width: 100%;
	height: 10vh;
}

/* components */

.theme-base-button {
	font-weight: 600 !important;
	padding: 8px 23px !important;
	border-radius: 25px !important;
}

.theme-base-button-sm {
	font-weight: 400 !important;
	padding: 5px 20px !important;
	border-radius: 8px !important;
	font-size: var(--font-size-xm);
}

.theme-base-button-md {
	font-weight: 600 !important;
	padding: 15px 20px !important;
	border-radius: 8px !important;
}

.button {
	cursor: pointer;
}

.option-buttons-container {
	height: auto;
	background: white;
	border-radius: 8px;
}

.option-button {
	display: inline-block;
	padding: 0 20px;
	line-height: 38px;
	background: var(--theme-primary);
	border: 1px solid var(--theme-nonary);
	font-size: var(--font-size-secondary);
	cursor: pointer;
}

.option-button-large {
	line-height: 54px;
	font-size: var(--font-size-secondary);
}

.option-button-left {
	border-radius: 8px 0px 0px 8px;
}

.option-button-right {
	border-radius: 0px 8px 8px 0px;
}

.option-button-active {
	border: 1px solid var(--theme-tertiary);
	color: white;
	font-weight: 700;
}

.option-button-inactive {
	background: white;
	border: 1px solid var(--theme-senary);
}

.mark-button {
	width: 20px;
	height: 20px;
	border-radius: 4px;
	background: var(--theme-primary);
	color: white;
	text-align: center;
	cursor: pointer;
}

.mark-button.inactive {
	color: var(--theme-octonary);
	background: var(--theme-octonary);
	border: 1px solid var(--theme-nonary);
}

.track-event-dropdown {
	width: 30%;
}

.visible-on-hover {
	visibility: hidden;
}

.table-row:hover .visible-on-hover {
	visibility: visible;
}

.footer {
	/* position: absolute;
	bottom: 0;
	left: 0;
	right: 0; */
	position: relative;
	background: white;
	padding: var(--content-padding);
	margin: 2rem 0;
}

.error-page {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
	background-color: #f8f8f8;
	font-family: Arial, sans-serif;
}

.error-heading {
	font-size: 48px;
	color: #ff4d4d;
}

.error-message {
	font-size: 24px;
	color: #333;
	margin-bottom: 10px;
}

.error-description {
	font-size: 18px;
	color: #777;
	margin-bottom: 20px;
}

.error-button {
	padding: 10px 20px;
	font-size: 16px;
	color: #fff;
	background-color: #428bca;
	border: none;
	border-radius: 4px;
	cursor: pointer;
}

.error-button:hover {
	background-color: #3071a9;
}

.floating-element {
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 9999;
}

.action-step-due-date-button {
	color: var(--theme-septenary) !important;
	height: 28px !important;
	min-width: 71px !important;
	font-size: 12px !important;
	line-height: 12px !important;
	padding: 8px 10px 8px 10px !important;
	border-radius: 16px !important;
	text-align: center;
	background-color: #F0F0F0 !important;
	text-decoration: none;
}

.action-step-due-date-button-active {
	color: var(--theme-primary) !important;
	height: 28px !important;
	min-width: 71px !important;
	font-size: 12px !important;
	line-height: 12px !important;
	padding: 8px 10px 8px 10px !important;
	border-radius: 16px !important;
	text-align: center;
	background-color: #F2663D33 !important;
	text-decoration: none;
}

.action-step-search-student-opt {
	display: flex !important;
	flex-direction: row !important;
	padding: 10px, 16px, 10px, 16px !important;
}

.action-step-search-student-opt-selected {
	background-color: var(--theme-octonary) !important;
	color: black !important;
}

.action-step-search-student-opt-img {
	width: 25.04px;
	height: 24;
	border-radius: 100%;
}

.action-step-search-student-opt-label {
	display: block;
	margin-left: 12px;
}

.action-step-search-student-opt-check {
	color: var(--theme-green);
	margin-left: auto;
}

.dashboard-container {
	width: fit-content;
	margin-top: 8px !important;
}

.dashboard-container-reports {
	padding-block: 24px;
	display: grid;
	grid-template-columns: max-content max-content;
	gap: 24px;
}

.dashboard-container-reports-header {
	font-family: var(--bs-body-font-family) !important;
	margin-bottom: 32px;
	display: flex;
	padding: 0 !important;
}

.dashboard-container-reports-header-detail {
	display: flex;
	flex-direction: column;
}

.dashboard-container-reports-header-detail:first-child {
	font-weight: 700 !important;
	font-size: 24px !important;
	margin: 0;
}

.dashboard-container-reports-header-detail:last-child {
	font-weight: 400 !important;
	font-size: 16px !important;
	margin: 0px;
}

.dashboard-container-reports-header-date-filter {
	margin-left: auto;
	height: 29px;
	background-color: #F0F1F5;
	border: none;
}

.dashboard-container-reports-header-date-filter-item {
	padding: 0 8px 0 8px !important;
	height: 21px !important;
	font-size: 15px !important;
	font-weight: 400;
	border: none !important;
	margin: 4px !important;
	line-height: 21px !important;
	text-transform: none !important;
}

.dashboard-container-reports-header-date-filter > .Mui-selected {
	background-color: white !important;
	border-radius: 2px;
	font-weight: 700;
}

.dashboard-student-stats {
	display: flex;
	column-gap: 24px;
}

.dashboard-student-stats-card {
	background-color: #FFFFFF;
	border-radius: 16px;
	padding: 24px;
	min-width: 252.25px;
	min-height: 104px;
}

.dashboard-student-stats-card > p {
	font-size: 14px;
	margin: 0;
	line-height: 24px;
}

.dashboard-student-stats-card-icon-count {
	display: flex;
	align-items: center;
	column-gap: 16px;
}

.dashboard-student-stats-card-icon-count > svg {
	width: 24px;
	height: 24px;
}

.dashboard-student-stats-card-icon-count > span {
	font-size: 30px;
}

.dashboard-student-stats-card-tooltip {
	font-size: 12px !important;
}

.dashboard-report-container {
	display: flex;
	flex-direction: column;
	background-color: white !important;
	border-radius: 16px;
	padding: 16px 0 16px 0;
	width: 528px;
	font-family: var(--bs-body-font-family) !important;
	margin: 0 !important;
	z-index: unset !important;
	filter: unset !important;
	box-shadow: 0px 4px 30px rgba(137, 145, 162, 0.12) !important;
}

.dashboard-report-container-carousel-btn {
	cursor: pointer;
	position: absolute;
	width: 43px;
	height: 43px;
	background-color: rgba(0, 0, 0, 0.3);
	border-radius: 50%;
}

.dashboard-report-container-carousel-btn:hover {
	background-color: rgba(0, 0, 0, 0.6);
}

.dashboard-report-container-library-options {
	font-size: 12px;
	font-weight: 400;
}

.dashboard-report-container-header {
	display: flex;
	align-items: center;
	padding-inline: 16px;
	padding-bottom: 20px;
	border-bottom: 1px solid #F0F0F0;
}

.dashboard-report-container-header > svg {
	margin-right: 10px;
}

.dashboard-report-container-header > p {
	font-size: 16px;
	font-weight: 700;
}

.dashboard-report-container-title {
	font-size: 12px !important;
	font-weight: 400 !important;
	padding-inline: 16px;
	padding-block: 16px;
}

.dashboard-report-container-body {
	padding-inline: 16px;
	background: none !important;
	z-index: 0 !important;
}

.dashboard-report-container-footer {
	padding-top: 16px;
	padding-inline: 16px;
	margin-top: auto;
	display: flex;
}

.dashboard-report-container-footer-see-all {
	all: unset;
	display: inline-block;
	cursor: pointer;
	font-size: 12px;
	font-weight: 400;
	line-height: 12px;
	color: #F2663D;
	text-decoration: underline;
	margin-left: auto;
}

.dashboard-report-course-progress {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-block: 16px;
	margin: 0 !important;
	background: none !important;
	z-index: 0 !important;
}

.dashboard-report-course-progress-doughnut {
	width: 147px;
}

.dashboard-report-course-progress-legends {
	margin-left: 52px;
	display: flex;
	row-gap: 12px;
	flex-direction: column;
}

.dashboard-report-course-progress-legends-item {
	display: flex;
	align-items: center;
	width: 196px;
}

/* icon */
.dashboard-report-course-progress-legends-item > span:nth-child(1) { 
	display: block;
	background-color: #F2663D;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	margin-right: 8px;
}

.dashboard-report-course-progress-legends-item > span:nth-child(2) {
	display: block;
	font-size: 14px;
	font-weight: 400;
}

.dashboard-report-course-progress-label {
	margin-left: auto;
	font-size: 14px;
	font-weight: 700;
}

.dashboard-report-course-progress-label > span {
	margin-left: auto;
	font-size: 10px;
	font-weight: 400;
}

.dashboard-report-interactions {
	margin-top: 24px;
	max-height: 614px;
	overflow-y: scroll;
	overflow-x: unset;
}

.dashboard-report-interactions-item {
	display: flex;
	align-items: center;
	padding: 10px 16px 10px 16px;
	border-bottom: 1px solid #F0F0F0;
}

.dashboard-report-interactions-item > span {
	font-size: 12px;
	font-weight: 700;
	text-wrap: nowrap;
}

.dashboard-report-interactions-item-title {
	font-size: 14px !important;
	font-weight: 500 !important;
	margin-right: 8px !important;
}

.dashboard-report-interactions-item > img {
	width: 46px;
	height: 38px;
	object-fit: cover;
	margin-inline: 8px;
}

.dashboard-report-interactions-item > span:last-child {
	margin-left: auto;
	font-size: 14px;
	font-weight: 700;
}

.dashboard-container-report-top-users {
	background: none !important;
	z-index: 0 !important;
}

.dashboard-container-report-top-users-3 {
	display: flex;
	justify-content: center;
	column-gap: 16px;
}

.dashboard-container-report-top-users-3-item {
	width: 137.5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.dashboard-container-report-top-users-3-item-profile-cont {
	position: relative;
	height: 66px;
	width: 66px;
	border-radius: 50%;
	border: 2px solid #9C9C9E;
}

.dashboard-container-report-top-users-3-item-profile-cont > img {
	height: 100%;
	width: 100%;
	border-radius: 50%;
}

.top-1 {
	order: 2;
}
.top-1 > .dashboard-container-report-top-users-3-item-profile-cont {
	border-color: #FFB863;
	width: 77px;
	height: 77px;
}
.top-1 > .dashboard-container-report-top-users-3-item-profile-cont > div {
	background-color: #FFB863 !important;
}

.top-2 {
	order: 3;
}
.top-2 > .dashboard-container-report-top-users-3-item-profile-cont {
	border-color: #F2663D;
}
.top-2 > .dashboard-container-report-top-users-3-item-profile-cont > div {
	background-color: #F2663D !important;
}

.top-3 {
	order: 1;
}
.top-3 > .dashboard-container-report-top-users-3-item-profile-cont {
	border-color: #9C9C9E;
}
.top-3 > .dashboard-container-report-top-users-3-item-profile-cont > div {
	background-color: #9C9C9E !important;
}

.dashboard-container-report-top-users-3-item-profile-cont > div {
	width: 24.93px;
	height: 24.93px;
	background-color: #9C9C9E;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	position: absolute;
	bottom: 0;
	right: 0;
	transform: translate(15%, 15%);
}

.dashboard-container-report-top-users-3-item-profile-cont > div > span {
	font-size: 14px;
	font-weight: 700;
	color: #FFFFFF;
}

.dashboard-container-report-top-users-3-item-details {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	row-gap: 4px;
	text-align: center;
}

.dashboard-container-report-top-users-3-item-details > p:nth-child(1) {
	margin: 0;
	font-size: 16px;
	font-weight: 500;
}

.dashboard-container-report-top-users-3-item-details > span {
	margin: 0;
	font-size: 14px;
	font-weight: 700;
}

.dashboard-container-report-top-users-3-item-details > p:nth-child(3) {
	margin: 0;
	font-size: 8px;
	font-weight: 400;
}

.dashboard-container-report-top-users-lists {
	display: flex;
	flex-direction: column;
	margin-top: 16px;
}

.dashboard-container-report-top-users-lists-item {
	display: flex;
	align-items: center;
	width: 100%;
	padding: 8px 16px 8px 16px;
	border-bottom: 1px solid #F0F0F0;
}

.dashboard-container-report-top-users-lists-item > span:first-child {
	width: 15px;
	font-weight: 700;
	font-size: 12px;
}

.dashboard-container-report-top-users-lists-item > img {
	width: 32px;
	height: 32px;
	border-radius: 50%;
	margin-inline: 8px;
}

.dashboard-container-report-top-users-lists-item > span:last-child {
	margin-left: auto;
	font-size: 14px;
	font-weight: 700;
}

.dashboard-container-report-top-users-lists-item-details {
	display: flex;
	flex-direction: column;
}

.dashboard-container-report-top-users-lists-item-details > span {
	display: block;
}

.dashboard-container-report-top-users-lists-item-details > :first-child {
	font-weight: 500;
	font-size: 14px;
}

.dashboard-container-report-top-users-lists-item-details > :last-child {
	font-weight: 400;
	font-size: 8px;
}

/* library details header start */
.library-details-header-body {
	width: 100%;
	height: 86px;
	background-color: white;
	border-top: 1px solid #EFEFEF;
	border-bottom: 1px solid #EFEFEF;
	padding: 24px 32px 24px 32px;
	display: flex;
}

.library-details-header-body-directories {
	display: flex;
	column-gap: 8px;
	align-items: center;
}
/* library details header end */

/* Main Sidebar Start */
.main-sidebar-container {
	display: flex;
	flex-direction: column;
	overflow: auto;
	position: relative;
	float: left;
	margin-inline: auto;
	row-gap: 8px;
	width: 207px;
}

.main-sidebar-container.close {
	width: 40px;
}

.main-sidebar-divider {
	width: 100%;
	height: 1px;
	border-bottom: 1px solid var(--theme-tertiary);
}

.main-sidebar-container-items {
	padding: 0 !important;
	display: flex;
	flex-direction: column;
	row-gap: 8px;
	overflow-x: hidden;
}

.subsidebar {
	display: flex;
	height: 100vh;
}

.main-sidebar-fixed {
	border-right: 1px solid var(--theme-primary);
	width: var(--sidebar-primary-options-open-width);
	display: flex;
	flex-direction: column;
	align-items: center;
	flex-shrink: 0;
	overflow-x: hidden;
	background-image: linear-gradient(var(--theme-primary) 20%, var(--theme-sidebar-secondary-color));
	transition: all 0.5s ease;
}

.assistant-gradient-button {
	border-right: 1px solid #FF9E72;
	width: var(--sidebar-primary-options-open-width);
	display: flex;
	flex-direction: column;
	align-items: center;
	flex-shrink: 0;
	overflow-x: hidden;
	background-image: linear-gradient(to right, #F2663D 20%, #FFB863);
	transition: all 0.5s ease;
}

.main-sidebar-fixed.close {
	width: var(--sidebar-primary-options-minimized-width);
}

.main-sidebar-logo-icon {
	margin-top: 32px;
	margin-bottom: 40px;
	margin-inline: auto;
	height: 40px;
}

.main-sidebar-logo-icon > img {
	height: 39px;
	object-fit: scale-down;
}
/* Main Sidebar End */

.subsidebar-collapsible {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding-inline: 16px;
	overflow-x: hidden;
	background-color: var(--theme-primary);
	scrollbar-width: none;
}

.subsidebar-collapsible.close {
	padding-inline: 0;
	width: 0;
	transition: all 0.3s ease;
}

.subsidebar-collapsible-clusters {
	margin-bottom: 25px;
}

.subsidebar-collapsible-header {
	width: 100%;
	display: flex;
	align-items: center;
	padding-left: 14px;
	margin-top: 32px;
	margin-bottom: 40px;
}

.subsidebar-collapsible-header-title {
	color: white !important;
	display: flex;
	align-items: center;
	column-gap: 8px;
}

.subsidebar-collapsible-header-collapse {
	margin-left: auto;
}

.subsidebar-collapsible-header-collapse > span {
	color: white !important;
	font-weight: var(--font-weight-bold) !important;
	font-size: 9px !important;
	text-decoration: underline;
	text-transform: uppercase;
}

.subsidebar-collapsible-title {
	color: white;
	width: 100% !important;
	line-height: 16.94px;
	padding-top: 8px;
	padding-bottom: 8px;
	padding-inline: 4px;
}

.subsidebar-collapsible-title-divider {
	width: 100%;
	height: 1px;
	border-bottom: 1px solid #FFFFFF1A;
	margin-top: 8px;
	margin-bottom: 8px;
}

.subsidebar-collapsible-title.interactive {
	cursor: pointer;
}

.subsidebar-collapsible-title.interactive:hover {
	background-color: var(--theme-tertiary);
	font-weight: var(--font-weight-bold) !important;
}

.subsidebar-collapsible-title.interactive.active {
	background-color: var(--theme-tertiary);
	font-weight: var(--font-weight-bold) !important;
}

.subsidebar-wide-options {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-inline: auto !important;
	row-gap: 8px;
	padding-left: 8px !important;
}

.subsidebar-wide-options-item {
	text-decoration: none;
	color: white;
	width: 100%;
	padding: 12px;
	border-radius: 4px;
	margin: 0 !important;
	cursor: pointer;
	display: flex;
	justify-content: start;
	align-items: center;
	color: white;
	transition: all 0.3s ease;
}

.subsidebar-wide-options-item.close {
	height: 48px;
	justify-content: center !important;
}

.subsidebar-wide-options-item.sidebar-toggle {
	justify-content: right;
	padding: 0 !important;
	cursor: default !important;
	height: 48px;
}

.subsidebar-wide-options-item.sidebar-toggle > div {
	color: white !important;
	width: 24px !important;
}

.subsidebar-wide-options-item.sidebar-toggle:hover {
	background-color: unset !important;
}

.subsidebar-wide-options-item.sidebar-toggle:hover {
	background-color: none !important;
}

.subsidebar-wide-options-item:hover {
	background-color: var(--theme-tertiary);
}

.subsidebar-wide-options-item:hover > .subsidebar-wide-options-item-text {
	font-weight: var(--font-weight-bold) !important;
}

.subsidebar-wide-options-item.disabled {
	opacity: 0.7;
	font-weight: var(--font-size-xm) !important;
	cursor: default !important;
}

.subsidebar-wide-options-item-text.active {
	font-weight: var(--font-weight-bold) !important;
}

.subsidebar-wide-options-item-text {
	line-height: 22px !important;
	margin-left: 16px;
	transition: width 0.5s ease-in;
	overflow: hidden;
}

.subsidebar-wide-options-item-text.close {
	width: 0 !important;
	overflow: hidden;
	margin: 0;
}

.subsidebar-wide-options-item.active {
	background-color: var(--theme-tertiary);
}

.subsidebar-wide-options-item.library-details-sidebar {
	padding: 8px;
	margin-bottom: 0;
	cursor: pointer;
}

.subsidebar-wide-options-item-text.library-details-sidebar {
	margin-left: 0;
}

.library-overview-main-container {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.library-overview-center-container {
	width: 80%;
	margin-inline: auto;
	display: flex;
	flex-direction: column;
}

.library-overview-edit-mode {
	padding: 1% var(--content-padding-x) 2% var(--content-padding-x);;
}

.library-overview-panels {
	padding: 32px !important;
}

.library-overview-panels > div:first-child {
	line-height: 26px;
	margin-bottom: 32px;
}

.library-overview-body {
	display: flex;
	flex-direction: column;
	row-gap: 32px;
}

.library-overview-body-adventures-banner {
	padding: 16px;
	border: 1px solid #CDDFD5;
	border-radius: 8px;
	background-color: #F3FCF7;
}

.library-overview-body-adventures-banner > div:first-child {
	margin-bottom: 24px;
}

.library-overview-body-elements {
	width: 100%;
	display: grid;
	grid-template-columns: auto auto;
	row-gap: 16px;
	column-gap: 16px;
}

.library-overview-body-elements-item {
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 634px;
}

.library-overview-body-elements-item > svg {
	width: 24px;
	height: 24px;
	margin-bottom: 8px;
}

.library-overview-body-elements-item-title {
	color: #F2663D;
	line-height: 16px;
	margin: 8px 0 8px 0;
}

.library-overview-learning-treasures {
	list-style: none;
	padding: 0;	
	margin: 0;
}

.library-overview-learning-treasures > li {
	display: flex;
	column-gap: 10px;
	align-items: center;
	margin-bottom: 16px;
}

.library-overview-ideal-explorers {
	display: grid;
	grid-template-columns: auto auto;
	column-gap: 16px;
	row-gap: 32px;	
	line-height: 24px;
}

.library-overview-ideal-explorers > div > svg {
	flex-grow: 0;
	flex-shrink: 0;
	margin-top: 2px;
}

.library-overview-ideal-explorers > div {
	display: flex;
	column-gap: 10px;	
}

.dotted-background.content-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-image: radial-gradient(circle at 7px 7px, lightgray 0, transparent 1px) !important;
	background-size: 15px 15px;
	border: 1px solid #E3E3E3;
	border-radius: 16px;
	padding: 40px;
}

.library-overview-mentor-details {
	display: flex;
	flex-direction: column;
	row-gap: 16px;
}

.library-overview-mentor-details > img{
	width: 256px;
	margin-inline: auto;
}

.library-overview-mentor-details-info {
	margin-inline: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	row-gap: 4px;
}

.library-overview-mentor-details-info-badge {
	font-weight: 600;
	font-size: 14px;
	padding: 7px 5px;
	background: linear-gradient(90deg, #F2663D 13.26%, #FFB964 126.24%);
	color: white;
	width: max-content;
	display: block;
}

.library-overview-training-preview {
	margin-top: 32px;
	width: 100%;
	position: relative;
	border: 2 solid green;
}

.library-overview-training-preview-completion {
	margin-bottom: 24px;
	color: var(--theme-quinary);
}

.inline-progess-bar {
	display: flex !important;
	flex-direction: row-reverse;
	align-items: center;
	column-gap: 8px;
}

.library-overview-training-preview-completion-main-cont {
	padding: 24px 16px;
	background-color: #F5F6F9;
	border-radius: 8px;
}

.library-details-progress-info {
	width: 100%;
	min-width: 336px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-top: 16px;
	margin-bottom: 16px;
}

.library-details-progress {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 4px;
}

.library-overview-training-preview-background {
	background: linear-gradient(135deg, var(--theme-primary) 0%, var(--theme-sidebar-secondary-color) 100%);
	height: 400px;
	width: 100vw;
	position: absolute;
	left: 50%;
    transform: translateX(-50%);
	z-index: 10;
}

.library-overview-training-preview-content {
	z-index: 999;
	position: inherit;
	padding: 32px;
	color: white;
}

.library-overview-training-preview-content-header {
	margin-bottom: 24px;
}

.library-overview-training-preview-content-break {
	width: 100%;
	height: 1px;
	border-bottom: 1px dashed #963B22;
	background: transparent;
	border: none;
	background-image: linear-gradient(to right, #963B22 50%, transparent 50%);
	background-size: 14px 1px;
}

.library-overview-training-preview-content-item {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.library-overview-training-preview-content-item-title {
	cursor: pointer;
	width: fit-content !important;
}

.library-overview-training-preview-content-item-title:hover {
	text-decoration: underline;
}

.library-overview-training-preview-content-item-lessons {
	display: flex;
	flex-direction: column;
}

.library-overview-training-preview-content-item-lessons > div {
	margin-top: 24px;
}

.library-overview-training-preview-content-item-lessons-interactive {
	cursor: pointer;
	width: fit-content !important;
}

.library-overview-training-preview-content-item-lessons-interactive.disabled {
	opacity: 0.8;
	cursor: default;
	text-decoration: none !important;
}

.library-overview-training-preview-content-item-lessons-interactive:hover {
	text-decoration: underline;
}

/* libraries dashboard banner start */
.libraries-header {
	width: 100%;
	height: 177px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background: url('https://files.lernnex.com/libraries-header-image-light.jpg'); 
	background-position: center;
	background-size: cover;
	padding: 32px 48px;
}

.libraries-header-filters {
	flex-grow: 0;
	display: flex;
	height: fit-content;
	width: 100%;
	height: 60px;
	z-index: 999;
}

.libraries-header-filters-select {
	background-color: white;
	height: 100%;
}

.libraries-header-filters-searchbar {
	display: flex;
	border: none !important;
	border-radius: 0 !important;
}

.libraries-header-filters-searchbar-container {
	background-color: white;
	width: 100%;
	display: flex;
	align-items: center;
	padding-right: 16px;
	border-radius: 4px;
	border: 1px solid #EFEFEF;
}

.libraries-header-filters-searchbar-btn {
	text-wrap: nowrap;
	flex-shrink: 0;
	padding: 12px 16px !important;
	height: fit-content !important;
	background: #454F6399 !important;
}

.libraries-header-filters-searchbar-btn > div {
	line-height: 16px !important;
}
/* libraries dashboard banner end */

/* conversation starter start */
.conversation-starter-container {
	border-inline: 0 !important;
	border-top: 0 !important;
	border-bottom: 1px solid #D9D9D9;
	padding-inline: 0 !important;
	padding-top: 8px !important;
	padding-bottom: 16px !important;
	border-radius: 0;
}

.conversation-starter-container > div {
	padding: 0 !important;
}
/* conversation starter end */

/* survey element start */
.survey-result-item {
	position: relative;
	display: flex;
	align-items: center;
}

.survey-result-item.disable {
	pointer-events: none;
}

.survey-result-item-progress-container {
	position: absolute;
	top: 0;
	left: -12px;
	width: calc(100% + 24px);
	height: 100%;
}

.survey-result-item-progress {
	border-radius: 4px;
	background-color: #D9D9D94D;
	height: 100%;
	transition: width 0.5s ease-in-out;
}

.survey-result-item-progress.selected {
	background-color: #2BAB6633;
}
/* survey element end */

/* base button start */
.transparent-base-button:hover {
	background-color: none !important;
}
/* base button end */

.mx-20 {
	margin-left: 20rem;
	margin-right: 20rem;
}

.action-steps-sidebar-badge {
	position: absolute;
	width: 100%;
	height: 100%;
}

.action-steps-sidebar-badge-cont {
	position: relative;
	width: 100%;
	height: 100%;
}

.action-steps-sidebar-badge-cont > div {
	position: absolute;
	top: -12px;
	right: 16px;
	display: flex;
	flex-direction: row;
	column-gap: 3px;
}

.action-steps-sidebar-badge-item {
	width: 24px;
	height: 24px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 1px 1px 1px 1px rgb(78, 78, 78);
}